<template>
  <el-dialog
    v-model="centerDialogVisible"
    :title="dialogTitle"
    width="30%"
    :close-on-click-modal="false"
  >
    <el-form
      size="mini"
      label-width="auto"
      ref="refForm"
      :disabled="Formdisabled"
      :model="subForm"
      :rules="formRulesMixin"
    >
      <el-card class="box-card">
        <el-row>
          <el-col :span="24">
            <el-row>
              <el-form-item
                label="考核子项名称"
                style="width: 80%"
                prop="name"
                :rules="formRulesMixin.isNotNull"
              >
                <el-input
                  placeholder="请输入考核子项名称"
                  v-model="subForm.name"
                ></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="考核子项分值" style="width: 50%" prop="score">
                <el-input-number v-model="subForm.score" :min="1" :max="100" />
              </el-form-item>
            </el-row>
            </el-col>
        </el-row>
      </el-card>
    </el-form>
    <el-divider></el-divider>
    <template #footer v-if="isHiddenButton">
      <span class="dialog-footer">
        <el-button type="primary" @click="AddForm" size="mini">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  defineExpose,
  getCurrentInstance,
  reactive,
  ref,
 // toRefs,
  defineEmits,
} from "vue";
import { ElNotification } from "element-plus";
import {StoreAssessSub , updateAssessSub} from "@/api/assess.js"
const emit = defineEmits(["fetchData"]);


//let { struct } = toRefs(state);
let { proxy } = getCurrentInstance();
let subForm = reactive({
  id: "",
  name: "",
  score: 100,
});
//是否显示Dialog
let centerDialogVisible = ref(false);
let isHiddenButton = ref(true);
let Formdisabled = ref(false)
//初始化Dialog标题
let dialogTitle = ref("");
//编辑框相关
const showModal = (isEdit, insertdata) => {
  if (isEdit) {
    isHiddenButton.value = true;

    dialogTitle.value = `编辑考核子项信息`;
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    reshowData(insertdata);
  } else {
    subForm.id = ''
    isHiddenButton.value = true;
    dialogTitle.value = "添加考核子项目";
    centerDialogVisible.value = true;
    Formdisabled.value = false;
    proxy.$nextTick(() => {
      if (proxy.$refs["refForm"] != undefined) {
     proxy.$refs["refForm"].resetFields();
 }
    });
  }
};

//修改
const reshowData = (row) => {
  proxy.$nextTick(() => {
    proxy.$refs["refForm"].resetFields();
    Object.keys(row).forEach((fItem) => {
      Object.keys(subForm).forEach((sItem) => {
        if (fItem === sItem) {
          subForm[sItem] = row[sItem];
        }else if(row[sItem] == undefined){
          subForm[sItem] = ' ';
        }
      });
    });
  });
};

const refForm = ref(null);
const eleMessage =(res) =>{
    let type = 'success'
    if(res.code !=200){
      type = 'error'
    }else if(res.code == 200){
      type = 'success'
      centerDialogVisible.value = false;
      emit("fetchData");
    }
     ElNotification({
            title: "提示",
            message: res.msg,
            type: type,
          });
}
//提交表单 校验
let AddForm = () => {
  refForm.value.validate((valid) => {
    if (valid) {
      //校验通过后的操作
      if (subForm.id) {
        
         //   updateReq()
        updateAssessSub(subForm).then((res) => {
         eleMessage(res)
          
        }); 
       
      } else {
        
        //  insertReq()
        
         StoreAssessSub(subForm).then((res) => {
          eleMessage(res)
         
        }); 
      }
    } else {
      return false;
    }
  });
};

defineExpose({
  showModal,
  eleMessage
});

</script>




<style lang="scss" scoped>
.box-card {
  margin-bottom: 5px;
}

.dialog-footer {
  text-align: right;
}

.el-dialog__footer {
  padding: var(--el-dialog-padding-primary);
  padding-top: 10px;
  text-align: right;
  box-sizing: border-box;
}
</style>