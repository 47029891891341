<template>
  <div class="top">
    <el-button type="primary" size="mini" @click="OpenForm()">新增</el-button>
  </div>
  <ListInfo ref="showForm" @fetchData="fetchData" />
  <el-table :data="tableData" border size="mini" :height="`calc(100vh - 210px)`" :highlight-current-row="true"
    style="width: 100%">
    <el-table-column type="selection" width="55" />
    <el-table-column type="index" :index="indexMethod" label="序号" prop="id"/>
    <el-table-column prop="name" align="center" label="考核子项目名称" />
    <el-table-column prop="score" align="center" label="分值" />
    <el-table-column label="编辑|删除" align="center">
      <template #default="{ row }">
        <el-button type="primary" :icon="Edit" size="mini" @click="tableEditClick(row)" circle></el-button>
        <el-popconfirm title="是否删除该考核项目?" @confirm="tableDelClick(row)">
          <template #reference>
            <el-button type="danger" :icon="Delete" size="mini" circle></el-button>
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>

  <el-pagination background layout="total,prev, pager, next,jumper" :total="pageStruct.total"
    :page-size="pageStruct.pagesize" :current-page="pageStruct.currentpage" @current-change="handleCurrentChange">
  </el-pagination>
</template>


<script setup>
  import {
    toRefs,
    reactive,
    onBeforeMount,
    ref,
    getCurrentInstance
  } from "vue";
  import {getAssessSubList , getAssessSub,DelAssessSub} from "@/api/assess"
  import {
    Edit,
    Delete,
  } from "@element-plus/icons";
  import ListInfo from "../sub/components/ListInfo.vue"
  let {
    proxy
  } = getCurrentInstance();
  const showForm = ref(null);
  //添加 修改
  let OpenForm = () => {
    proxy.$nextTick(() => {
      showForm.value.showModal();
    });
  };
  //查看
  // let pagesize = 20;
  let pageStruct = reactive({
    currentpage: 1,
    total: 0,
    pagesize: 20
  })
  //修改
  let tableEditClick = (row) => {
    getAssessSub(row.id).then((response) => {
      if (response.code != 200) {
        return false;
      }
      proxy.$nextTick(() => {
        showForm.value.showModal(true, response.data);
      });
    });
  };
  //删除
  let tableDelClick = (row) => {
    DelAssessSub(row.id).then((response) => {
      showForm.value.eleMessage(response)
      fetchData()
    });
  };

  const state = reactive({
    tableData: null,
  });
  onBeforeMount(() => {
    fetchData()
  });
  const fetchData = () => {
    getAssessSubList(pageStruct.currentpage).then((response) => {
      state.tableData = response.data.list;
      pageStruct.total = response.data.count
    });
  };
  //分页变化
  const handleCurrentChange = (page) => {
    pageStruct.currentpage = page
    fetchData()
  }
  const indexMethod = (index) => {
  return index+1;
}

  //导出属性到页面中使用
  let {
    tableData
  } = toRefs(state);
</script>

<style scoped>
  .top {
    margin-bottom: 20px;
  }

  .el-pagination {
    text-align: center;
  }
</style>
